import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { MDXRenderer } from "gatsby-plugin-mdx";
import portrait from "../assets/images/Surboom-portrait.png";

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center"
  },
  subContainer: {
    margin: `${theme.spacing(6)}px 0px`,
    // alignSelf: "start",
    "& p" :{
      textAlign: "start !important",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    "& h3": {
      margin: `${theme.spacing(8)}px 0px 0px`,
      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(0)}px 0px 0px`,
      },
    },
    "& h6": {
      margin: `${theme.spacing(4)}px 0px 0px`,
    },
    "& a": {
      textDecoration: "none",
    fontWeight: 600,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.yellow.main,
      color: theme.palette.yellow.contrastText,
    },
    "&:visited": {
      color: theme.palette.primary.main,
    },
    }
  },
  containerImg: {
    width: "50%",
    margin: theme.spacing(4),
  }
}));
const AboutPage = ({data}) => {
  const { mdx: { frontmatter, body } } = data;
  const classes = useStyles();
  return (
    <Layout pageName="about">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <Typography className={classes.title} variant="h1">{frontmatter.title}</Typography>
      <Grid
        item
        xs={12} sm={10} md={8} lg={6} xl={5} 
        className={classes.subContainer}
      > <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <MDXRenderer className={classes.containerText}>{body}</MDXRenderer>
        <img className={classes.containerImg} src={portrait} alt="Surboom-portrait-travail" />
        <Typography variant='h5'>Suivez notre aventure sur le compte Instagram <a href={"https://www.instagram.com/atelier.surboom/"} target="_blank" rel="noreferrer">@atelier.surboom</a> !
        </Typography>
      </Grid>
    </Grid>
    </Grid>
    </Layout>
  );
};
export const aboutQuery = graphql`
{
  mdx(frontmatter: {name: {eq: "about"}}) {
    body 
    frontmatter {
      title
    }
  }
}
`
export default AboutPage;
